<template>
  <div>
    <b-form @submit.prevent>
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Fecha y hora de solicitud"
            label-for="dateTime"
          >
            <b-form-input
              id="dateTime"
              v-model="formData.created_at"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Subarea"
            label-for="Subarea"
          >
            <b-form-input
              id="Subarea"
              v-model="formData.subarea"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="formData.responsable"
          md="6"
        >
          <b-form-group
            label="Responsable"
            label-for="responsable"
          >
            <b-form-input
              id="responsable"
              v-model="formData.responsable"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Estado"
            label-for="state"
          >
            <v-select
              id="state"
              v-model="formData.state"
              label="text"
              placeholder="Seleccione estado"
              class="w-100"
              :options="statuses"
              :reduce="(option) => option.id"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Tipo de solicitud"
            label-for="maintenance_type_name"
          >
            <b-form-input
              id="maintenance_type_name "
              v-model="formData.maintenance_type_name"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="formData.finished_date"
          md="6"
        >
          <b-form-group
            label="Fecha y hora de finalización"
            label-for="finished_date"
          >
            <b-form-input
              id="finished_date"
              v-model="formData.finished_date"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Observación"
            label-for="observations"
          >
            <b-form-textarea
              id="observations"
              v-model="formData.observations"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="formData.technician_observations"
          md="12"
        >
          <b-form-group
            label="Observación tecnica"
            label-for="technician_observations"
          >
            <b-form-textarea
              id="technician_observations"
              v-model="formData.technician_observations"
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
	BForm,
	BFormGroup,
	BButton,
	BCol,
	BFormInput,
	BRow,
	BSpinner,
	BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
	inject: ['serviceNotificationRepository'],
	components: {
		BForm,
		BFormGroup,
		BCol,
		BFormInput,
		BRow,
		vSelect,
		BFormTextarea,
	},
	props: {
		record: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			formData: {
				subarea: '',
        responsable: '',
        state: '',
        maintenance_type_name: '',
        finished_date: '',
        observations: '',
        technician_observations: '',
        created_at: '',
			},
			statuses: [
				{
					id: '1',
					text: 'Sin empezar',
				},
				{
					id: '2',
					text: 'En proceso',
				},
				{
					id: '3',
					text: 'Terminado',
				},
				{
					id: '4',
					text: 'Cancelado',
				},
			],
		}
	},
	async mounted() {
		const me = this
		if (me.record) {
			me.formData = {
        subarea: me.record.subarea.name,
        responsable: me.record.responsable,
        state: me.record.state,
        maintenance_type_name: me.record.maintenance_type.name,
        finished_date: me.record.finished_date,
        observations: me.record.observations,
        technician_observations: me.record.technician_observations,
        created_at: me.record.created_at,
      }
		}
	},
	methods: {},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
