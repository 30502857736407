<template>
  <div>
    <b-row class="mb-2">
      <b-col cols="12">
        <div class="d-flex">
          <b-button
            id="btn1"
            class="btn__ active-btn mr-2"
            @click="isActive('btn1')"
          >
            <span class="align-middle">Sin iniciar</span>
          </b-button>
          <b-button
            id="btn2"
            class="btn__ mr-2"
            @click="isActive('btn2')"
          >
            <span class="align-middle">En proceso</span>
          </b-button>
          <b-button
            id="btn3"
            class="btn__ mr-2"
            @click="isActive('btn3')"
          >
            <span class="align-middle">Terminados</span>
          </b-button>
          <b-button
            id="btn4"
            class="btn__ mr-2"
            @click="isActive('btn4')"
          >
            <span class="align-middle">Cancelados</span>
          </b-button>
          <!-- <b-button
            id="btn5"
            class="btn__"
            @click="isActive('btn5')"
          >
            <span class="align-middle">Listado de camas</span>
          </b-button> -->
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-col cols="12 mb-0">
        <b-card
          no-body
        >
          <location-table
            :busy="isBusy"
            :records="records"
            :is-visible="isVisible"
            @detail="handleDetail"
            @editing="handleEditing"
            @deleting="handleDeleting"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="modal-service-notification"
      centered
      title="Proceso de realización del servicio"
      hide-footer
    >
      <form-service-notification
        :record="record"
        :record2="record2"
        :is-process="isProcess"
        :state="state"
        @save="loadServiceNotifications"
      />
    </b-modal>
    <b-modal
      id="modal-detail-service"
      centered
      title="Detalle solicitud"
      hide-footer
    >
      <form-detail-service
        :record="record"
      />
    </b-modal>
  </div>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
  BButton,
} from 'bootstrap-vue'
import FormServiceNotification from './components/FormServiceNotification'
import FormDetailService from './components/FormDetailService'
import LocationTable from './components/LocationTable'

export default {
  inject: ['serviceNotificationRepository'],
  components: {
    BCard,
    BRow,
    BCol,
    LocationTable,
    FormServiceNotification,
    FormDetailService,
    BButton,
  },

  data() {
    return {
      record: null,
      record2: null,
      records: [],
      module: 'generalService',
      isBusy: false,
      isProcess: false,
      isVisible: true,
      state: '2',
      type: '1',
    }
  },
  mounted() {
    this.loadServiceNotifications()
  },
  methods: {
    async loadServiceNotifications() {
      const me = this
      try {
        me.records = []
        me.isBusy = true
        const { data } = await me.serviceNotificationRepository.getAll(me.type)
        me.records = data
      } catch (error) {
        // console.log(error)
      } finally {
        me.isBusy = false
      }
    },
    handleEditing(item) {
      const me = this
      me.record = {
        finished_date: item.finished_date,
        responsable: item.responsable,
        state: item.state,
        technician_observations: item.technician_observations,
        id: item.id,
        created_at: item.created_at,
      }

      me.record2 = item
			this.$bvModal.show('modal-service-notification')
    },
    handleDetail(item) {
      this.record = item
      this.$bvModal.show('modal-detail-service')
    },
    handleDeleting(item) {
      const me = this
      me.$bvModal
				.msgBoxConfirm('¿Está seguro que desea cancelar esta solicitud?', {
					title: 'Cancelar solicitud',
					size: 'sm',
					okVariant: 'danger',
					okTitle: 'Cancelar',
					buttonSize: 'sm',
					hideHeaderClose: false,
					cancelTitle: 'Cerrar',
					cancelVariant: 'outline-secondary',
					centered: true,
					modalClass: 'modal-danger',
				})
				.then(async value => {
					if (value) {
            try {
              const data = {
                state: '4',
              }
              await me.serviceNotificationRepository.update(
                item.id,
                data,
              )
              me.$bvToast.toast('Registro cancelado con éxito', {
                title: 'Éxito',
                toaster: 'b-toaster-bottom-right',
                variant: 'success',
                solid: true,
                autoHideDelay: 3000,
              })
              me.loadServiceNotifications()
            } catch (error) {
              // console.log(error)
              me.$bvToast.toast('Error', {
                title: 'Ocurrió un error al guardar el registro',
                toaster: 'b-toaster-bottom-right',
                variant: 'danger',
                solid: true,
                autoHideDelay: 3000,
              })
            }
					}
				})
    },
    isActive(id) {
			const me = this
			if (id == 'btn1') {
        me.type = '1'
        me.state = '2'
        me.isVisible = true
        me.isProcess = false
        me.loadServiceNotifications()
      } else if (id == 'btn2') {
        me.type = '2'
        me.state = '3'
        me.isVisible = true
        me.isProcess = true
        me.loadServiceNotifications()
      } else if (id == 'btn3') {
        me.type = '3'
        me.isVisible = false
        me.isProcess = false
        me.loadServiceNotifications()
      } else if (id == 'btn4') {
        me.type = '4'
        me.isVisible = false
        me.isProcess = false
        me.loadServiceNotifications()
      } else {
        me.$router.push('/listado-de-camas')
      }

			const btns = document.querySelector('.active-btn')
			const btn = document.getElementById(id)

			if (btns) btns.classList.remove('active-btn')
      btn.classList.add('active-btn')
		},
  },

}
</script>

<style lang="scss" scoped>
.btn__ {
	background-color: #fff !important;
	border: 1px solid #7367f0 !important;
	padding: 8px !important;
	color: #7367f0 !important;
	border-radius: 0.5rem !important;
	width: 20% !important;
	text-align: center !important;
}

.btn__.active-btn {
	background-color: #7367f0 !important;
	color: #fff !important;
}
</style>
