<template>
  <div>
    <b-form @submit.prevent>
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Responsables*"
            label-for="responsable"
          >
            <b-form-input
              id="responsable"
              v-model="formData.responsable"
              placeholder="Responsables"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Estado*"
            label-for="state"
          >
            <v-select
              id="state"
              v-model="formData.state"
              label="text"
              placeholder="Seleccione estado"
              class="w-100"
              :options="statuses"
              :reduce="(option) => option.id"
              :disabled="state == '2'"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="isProcess"
          md="6"
        >
          <b-form-group
            label="Fecha de finalización*"
            label-for="finished_date2"
          >
            <b-form-input
              id="finished_date2"
              v-model="formData.finished_date2"
              type="date"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="isProcess"
          md="6"
        >
          <b-form-group
            label="Hora de finalización*"
            label-for="finished_time"
          >
            <b-form-input
              id="finished_time"
              v-model="formData.finished_time"
              type="time"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Tipo de solicitud"
            label-for="maintenance_type_name"
          >
            <b-form-input
              id="maintenance_type_name "
              v-model="record2.maintenance_type.name"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Observación"
            label-for="observations"
          >
            <b-form-textarea
              id="observations"
              v-model="record2.observations"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="isProcess"
          md="12"
        >
          <b-form-group
            label="Observación tecnica"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="formData.technician_observations"
              placeholder="Descripción"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" />
        <b-col
          md="6"
          class="text-right"
        >
          <b-button
            type="button"
            variant="primary"
            :disabled="isBusy || $v.$invalid"
            @click="saveData"
          >
            <b-spinner
              v-if="isBusy"
              small
            />
            <v-icon
              v-else
              name="save"
            />
            Guardar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
	BForm,
	BFormGroup,
	BButton,
	BCol,
	BFormInput,
	BRow,
	BSpinner,
	BFormTextarea,
} from 'bootstrap-vue'
import { required } from 'vuelidate/lib/validators'
import vSelect from 'vue-select'

export default {
	inject: ['serviceNotificationRepository'],
	components: {
		BForm,
		BFormGroup,
		BButton,
		BCol,
		BFormInput,
		BRow,
		BSpinner,
		vSelect,
		BFormTextarea,
	},
	props: {
		record: {
			type: Object,
			default: null,
		},
    record2: {
      type: Object,
			default: null,
    },
    isProcess: {
      type: Boolean,
      default: false,
    },
    state: {
      type: String,
      default: null,
    },
	},
	data() {
		return {
			formData: {
				responsable: '',
				state: null,
				finished_date: '',
        finished_date2: '',
				technician_observations: '',
        finished_time: '',
			},
      statuses: [
				{
					id: '1',
					text: 'Sin empezar',
				},
				{
					id: '2',
					text: 'En proceso',
				},
				{
					id: '3',
					text: 'Terminado',
				},
			],
			isBusy: false,
		}
	},
	async mounted() {
		const me = this
		if (me.record) {
			me.formData = { ...me.record }
      me.formData.finished_date2 = me.formData.finished_date
      if (me.state) me.formData.state = me.state
		}
	},
	validations() {
    const me = this
    const defaultValidation = {
      responsable: {
				required,
			},
			state: {
				required,
			},
    }

    return me.formData.state == '3' ?
      {
        formData: {
          ...defaultValidation,
          finished_date2: {
            required,
          },
          technician_observations: {
            required,
          },
          finished_time: {
            required,
          },
        },
      } : {
        formData: { ...defaultValidation },
      }
	},
	methods: {
		async saveData() {
			const me = this
			try {
        me.isBusy = true

        if (me.formData.finished_date2) {
          me.formData.finished_date = `${me.formData.finished_date2} ${me.formData.finished_time}:00`
        }

        if (me.formData.finished_date == '') delete me.formData.finished_date
        if (me.formData.technician_observations == '') delete me.formData.technician_observations

				await me.serviceNotificationRepository.update(
					me.formData.id,
					me.formData,
				)
        me.formData.finished_date = ''
        me.formData.finished_time = ''
        me.formData.finished_time2 = ''
        me.formData.technician_observations = ''
        this.$bvModal.hide('modal-service-notification')
        me.$bvToast.toast('Registro guardado con éxito', {
          title: 'Éxito',
          toaster: 'b-toaster-bottom-right',
          variant: 'success',
          solid: true,
          autoHideDelay: 3000,
        })
        me.$emit('save')
			} catch (error) {
				// console.log(error)
        me.$bvToast.toast('Error', {
					title: 'Ocurrió un error al guardar el registro',
					toaster: 'b-toaster-bottom-right',
					variant: 'danger',
					solid: true,
					autoHideDelay: 3000,
				})
			} finally {
        me.isBusy = false
      }
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
