<template>
  <b-row>
    <b-col
      md="4"
      class="align-self-center pl-3"
    >
      <b-button
        v-if="hasPermission(`${module}.new`)"
        variant="success"
        size="sm"
        class="bg-daren-2"
        :disabled="isDisabled"
        @click="() => $emit('creating')"
      >
        <feather-icon icon="PlusIcon" />
        Nuevo
      </b-button>
    </b-col>
    <b-col
      md="8"
      class="my-1 pr-3"
    >
      <b-form-group
        label="Buscar"
        label-cols-sm="3"
        label-align-sm="right"
        label-for="filterInput"
        class="mb-0 w-75 float-right"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder=""
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Limpiar
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :fields="fields"
        :items="records"
        :filter="filter"
        :per-page="dataPerPage"
        :current-page="pagination.currentPage"
        :busy="busy"
        show-empty
        :filter-included-fields="filterOn"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Cargando datos...</strong>
          </div>
        </template>
        <template #empty>
          <h4 class="text-center py-2">
            No hay registros
          </h4>
        </template>
        <template
          #cell(state)="data"
        >
          <div class="d-flex m-0 p-0 align-items-center">
            <b-badge
              class="mr-1"
              :variant="statusColor(data.item.state)"
            >
              {{ validaStatus(data.item.state) }}
            </b-badge>
            <b-spinner
              v-if="data.item.state == '1'"
              type="grow"
              label="Loading..."
              variant="primary"
            />
          </div>
        </template>
        <template #cell(responsable)="data">
          {{ data.item.responsable ? data.item.responsable : 'Sin asignar' }}
        </template>
        <template #cell(time_final)="data">
          {{ calculateTime(data.item) }}
        </template>
        <template #cell(actions)="data">
          <div class="d-flex align-items-center">
            <b-button
              v-b-tooltip.hover.top="'Detalle'"
              variant="info"
              class="btn-icon mr-25"
              size="sm"
              @click.prevent="() => $emit('detail', data.item)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-button
              v-if="isVisible"
              v-b-tooltip.hover.top="'Realizar'"
              variant="primary"
              class="btn-icon mr-25"
              size="sm"
              @click.prevent="() => $emit('editing', data.item)"
            >
              <feather-icon icon="CheckCircleIcon" />
            </b-button>
            <b-button
              v-if="isVisible"
              v-b-tooltip.hover.top="'Cancelar'"
              variant="danger"
              class="btn-icon mr-25"
              size="sm"
              @click.prevent="() => $emit('deleting', data.item)"
            >
              <feather-icon icon="XCircleIcon" />
            </b-button>
          </div>
        </template>
      </b-table>
    </b-col>
    <b-col
      md="6"
      class="pl-3"
    >
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50">Páginas</label>
        <b-form-select
          v-model="pagination.perPage"
          size="sm"
          :options="pagination.pageOptions"
          class="w-50"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="6"
      class="pr-3 pb-2"
    >
      <b-pagination
        v-model="pagination.currentPage"
        :total-rows="pagination.totalRows"
        :per-page="pagination.perPage"
        align="right"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
	BTable,
	BCol,
	BRow,
	BFormGroup,
	BFormInput,
	BInputGroup,
	BInputGroupAppend,
	BButton,
	BPagination,
	BFormSelect,
	BSpinner,
  BBadge,
	VBTooltip,
} from 'bootstrap-vue'
import moment from 'moment'
import PermissionMixin from '@/mixins/PermissionMixin'

moment.locale('es')

export default {
	name: 'BaseTable',
	components: {
		BTable,
		BCol,
		BRow,
		BFormGroup,
		BFormInput,
		BInputGroup,
		BInputGroupAppend,
		BButton,
		BPagination,
		BFormSelect,
		BSpinner,
    BBadge,
	},
	directives: {
		'b-tooltip': VBTooltip,
	},
	mixins: [PermissionMixin],
	props: {
		records: {
			type: Array,
			default: () => [],
		},
		busy: {
			type: Boolean,
			default: false,
		},
		module: {
			type: String,
			default: null,
		},
    isVisible: {
      type: Boolean,
      default: false,
    },
	},
	data() {
		return {
			filter: this.search,
			pagination: {
				perPage: 10,
				pageOptions: [10, 20, 50],
				totalRows: 1,
				currentPage: 1,
			},
			filterOn: [],
			sortDesc: false,
			sortDirection: 'asc',
			fields: [
          {
            key: 'subarea.name',
            label: 'Subarea',
          },
          {
            key: 'state',
            label: 'Estado',
          },
          {
            key: 'maintenance_type.name',
            label: 'Tipo de solicitud',
          },
          {
            key: 'responsable',
            label: 'Responsable',
          },
          {
            key: 'created_at',
            label: 'Fecha y hora de solicitud',
          },
          {
            key: 'finished_date',
            label: 'Fecha y hora de finalización',
          },
          {
            key: 'time_final',
            label: 'Tiempo demora el servicio',
          },
          {
            key: 'actions',
            label: 'Acciones',
          },
      ],
		}
	},
	computed: {
		dataPerPage() {
			const me = this
			return me.paginate ? me.pagination.perPage : me.records.length
		},
	},
	mounted() {
		this.pagination.totalRows = this.records.length
	},
	methods: {
		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length
			this.pagination.currentPage = 1
		},
    validaStatus(state) {
      let text = ''

      if (state == '1') {
        text = 'Sin empezar'
      } else if (state == '2') {
        text = 'En proceso'
      } else if (state == '3') {
        text = 'Terminado'
      } else {
        text = 'Cancelado'
      }

      return text
    },
    statusColor(state) {
      let vatiant = ''

      if (state == '1') {
        vatiant = 'primary'
      } else if (state == '2') {
        vatiant = 'success'
      } else if (state == '3') {
        vatiant = 'warning'
      } else {
        vatiant = 'danger'
      }

      return vatiant
    },
    calculateTime(item) {
      let text = null
      const initial = moment(item.created_at, 'YYYY-MM-DD HH:mm:ss')
      const final = item.finished_date ? moment(item.finished_date, 'YYYY-MM-DD HH:mm:ss') : null

      const hourInitial = item.created_at.split(' ')[1]
      const hourFinal = item.finished_date ? item.finished_date.split(' ')[1] : null

      if (initial && final) {
        const day = final.diff(initial, 'days')
        text = `${day} día(s)`

        const diff = moment(hourFinal, 'HH:mm:ss').diff(moment(hourInitial, 'HH:mm:ss'))
        const duration = moment.utc(diff).format('HH:mm:ss')

        // const hours = duration.split(':')[0]
        // const minutes = duration.split(':')[1]
        // const seconds = duration.split(':')[2]
        text = `${text} ${duration}`
      }

      return text
    },
	},
}
</script>

<style lang="scss" scoped></style>
